import axios from 'axios';
import {message} from 'antd';
console.log('请求时间',new Date())
axios.defaults.baseURL = 'https://www.sgidi.top/';
// axios.defaults.headers.common['Authorization'] ="Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTmFtZSI6ImRldl91c2VyIiwidWlkIjoiMCIsImV4cCI6MTYyNDY5MDQ5OH0.ER2j8Bcy8CYo_lhJ5oXneOH0hgzculNEdKbb5MqUoTU";
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  if(error.response.status===404){
    message.error(error.response.data.detail)
  }
  message.error('用户名或者密码不正确')
  return Promise.reject(error);
});

function request(url,method,data,token){
  return axios({
    url:url,
    method:method,
    data:data,
    headers:{
      Authorization:token
    }
  }).then((response)=>{
    return response.data;
  })
}

export default request;