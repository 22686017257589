import React from 'react';
import { Form, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import request from './http';
import  './login.css'
const Login=(props)=>{
  const onFinish = (values) => {
    console.log('Received values of form: ', values);
    let formData=new FormData();
    formData.append('username',values.username)
    formData.append('password',values.password)
    request('token','post',formData).then(res=>{
      console.log(res)
      if(res){
        window.location.href="https://datav.aliyuncs.com/share/0eb8c3f64e646e576a98364e0a7505ab?token=Bearer "+res.access_token
      }
    })
  };

  return (
    <div className="login_body">
      <div className="login_container">
        <Form
          name="normal_login"
          layout={'vertical'}
          className="login_form"
          onFinish={onFinish}
        >
          <div style={{color:'#fff',textAlign:'center',fontSize:'24px',marginBottom:20}}>运营轨道交通结构安全预警系统</div>
          <Form.Item
            name="username"
            label="用户名"
            rules={[{ required: true, message: '请输入用户名' }]}
          >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item
            name="password"
            label="密码"
            rules={[{ required: true, message: '请输入密码' }]}
          >
            <Input
              prefix={<LockOutlined className="site-form-item-icon" />}
              type="password"
              placeholder="请输入密码"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
    
    
  );
}

export default Login