import React,{Component} from 'react';
import { Row, Col } from 'antd';
// import request from './http';
import 'antd/dist/antd.css';
import './statistic.css';

class Statistic extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      img_name_list:[
        {
          name:'年龄-地质分区-收敛气泡图',
          img_name:'bubble_age',
          en_name:'bubble_age.png',
          desc:'反映隧道管片不同年龄、地质分区与收敛变形分布情况的的二维气泡图'
        },
        {
          name:'埋深-地质分区-收敛气泡图',
          img_name:'bubble_depth',
          en_name:'bubble_depth.png',
          desc:'反映隧道管片不同埋深、地质分区与收敛变形分布情况的的二维气泡图'
        },
        {
          name:'三维散点图',
          img_name:'d_scatter',
          en_name:'3D_Scatter.gif',
          desc:'隧道管片全数据四类特征（年龄、埋深、地质分区及收敛值）三维散点图'
        },
        {
          name:'二维核密度图',
          img_name:'kde',
          en_name:'kde.png',
          desc:'隧道管片不同年龄、收敛、埋深和地质分区的二维核密度图'
        },
        {
          name:'小提琴图',
          img_name:'violin_plot',
          en_name:'violin_plot.png',
          desc:'反映古河道地层和正常地层的隧道在不同年龄阶段的收敛集中程度和分散程度'
        },
        
      ],
      img_blob:[]
    }
  }
  componentDidMount(){
    console.log('statistic',this.props)
  }

  getImageBlob(url,cb) {
    var xhr  = new XMLHttpRequest();
    xhr.open("get", "https://www.sgidi.top/img/"+url, true);
    xhr.setRequestHeader("Authorization",this.props.match.params.token);
    xhr.responseType = "blob";
    xhr.onload = function() {
      if (this.status === 200) {
          if(cb) cb(this.response);
      }
    };
    xhr.send();
  }

  preView(url,id){
    let reader = new FileReader();
    
    this.getImageBlob( url , function(blob){
      reader.readAsDataURL(blob);
    });
    
    reader.onload = function(e) {
      var img = document.createElement("img");
      img.src = e.target.result;
      var img_id=document.getElementById(id);
      console.log('容器',img_id);
      // img_id.innerHTML=img;
      img_id.appendChild(img);
      // document.body.appendChild(img);
    }
   }

  render() { 
    const {img_name_list}=this.state;
    // console.log('img_blob',this.preView(img_name_list[0]['en_name']))
    return ( 
      <div>
        <Row>
          {
            img_name_list.map((img,index)=>{
              return (
                <Col  key={img.img_name} span={24}>
                    <div className={img.img_name}>
                      <div className="title">{img.name}</div>
                      {this.preView(img.en_name,img.img_name)}
                      <div className="img" id={img.img_name}></div>
                      <div className="desc" style={{textAlign:'center',fontWeight:'bold',fontSize:'14px'}}>{img.desc}</div>
                    </div>
                    
                </Col>
              )
            })
          }
        </Row>
      </div>
     );
  }
}
 
export default Statistic;