import React, { Component } from 'react';
import * as echarts from 'echarts';
import 'antd/dist/antd.css'
import { Select,Spin } from 'antd';
import request from './http';
import './graph.css';

const { Option } = Select;

class Graph extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      loading:true  
    }
  }
  componentDidMount(){
    console.log('token',this.props)
    this.getGraph(2014)
  }

  getGraph=(year)=>{
    request('graph?year='+year,'get','',this.props.match.params.token).then(res=>{
      this.setState({loading:false})
      var myChart = echarts.init(document.getElementById('graph'));
      console.log('graph',res);
      myChart.setOption({
        ...res.option,
        title:[
          {
            ...res.option.title[0],
            top:'bottom',
            left:'center'
          }
        ],
        series:[
          {
            ...res.option.series[0]
          }
        ]
      });
    })
  }

  handleChange=(e)=>{
    console.log('handle',e);
    this.setState({loading:true},()=>{
      this.getGraph(e);
    });
    
  }
  render() { 
    const {loading}=this.state;
    return ( 
      <div className="container">
        <div className="year">
          <span style={{color:'#fff'}}>请选择年份：</span>
          <Select defaultValue={2014} style={{ width: 120 }} onChange={this.handleChange}>
            <Option key="2014" value={2014}>2014</Option>
            <Option key="2015" value={2015}>2015</Option>
            <Option key="2016" value={2016}>2016</Option>
            <Option key="2017" value={2017}>2017</Option>
            <Option key="2018" value={2018}>2018</Option>
            <Option key="2019" value={2019}>2019</Option>
            <Option key="2020" value={2020}>2020</Option>
          </Select>
        </div>
        <div className="graph_container">
          {
            loading?<Spin size="large" spinning={loading}></Spin>:<div id="graph"></div>
          }
        </div>
      </div>
    );
  }
}
 
export default Graph;