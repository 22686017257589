import React, { Component } from 'react';
import * as echarts from 'echarts';
import './ring.css'

class Ring extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }

  componentDidMount(){
    const {id,ringCharts}=this.props;
    console.log('ring-id',id);
    this.getRingInfo(ringCharts);
    
  }

  getRingInfo=(charts)=>{
    var myChart = echarts.init(document.getElementById('line'));
    var piesData=[];
    for(let i=0;i<charts.length;i++){
      if(charts[i].chart_type==='pie'){
        piesData.push(charts[i])
      }
    }
    myChart.setOption({
      ...charts[4].option,
      dataZoom:{
        ...charts[4].option.dataZoom,
        start:40,
        end:60,
      }
    });
    myChart.on('click',(params)=>{
      console.log('click',params)
    })
    for(var j=0;j<piesData.length;j++){
      var piechart=echarts.init(document.getElementById('pies_'+(j+1)));
      piechart.setOption({
        ...piesData[j].option,
        title:{
          text:piesData[j].chart_name,
          left:'center'
        },
        legend:[
          {
            ...piesData[j].legend,
            bottom:-5,
            left:'center'
          }
        ]
      })
    }
  }

  render() { 
    return ( 
      <div>
        <div id="line" style={{width:'100%',height:'450px'}}></div>
        <div className="pies">
          <div className="pie" id="pies_1">1</div>
          <div className="pie" id="pies_2">2</div>
          <div className="pie" id="pies_3">3</div>
          <div className="pie" id="pies_4">4</div>
        </div>
      </div>
    );
  }
}
 
export default Ring;