import {BrowserRouter as Router,Route} from 'react-router-dom';
import App from './App';
import Statistic from './Statistic'
import Graph from './Graph'
import Login from './Login'

function AppRouter(){
  return (
    <Router>
      <Route path="/" exact component={Login}></Route>
      <Route path="/app" exact component={App}></Route>
      <Route path="/statistic/:token" exact component={Statistic}></Route>
      <Route path="/graph/:token" exact component={Graph}></Route>
    </Router>
  )
}

export default AppRouter;