import React, { Component } from 'react';
import { Map,Marker } from 'react-amap';
import 'antd/dist/antd.css'
import {Form,Select,Button,Modal,Spin} from 'antd';
import request from './http';
import './app.css';
import Ring from './Ring'
import marker from './mark.png';
const AMAP_KEY="613b8fa147b52fb5b44c0c3013c177ba"
const VERSION="1.4.15"
const MAP_STYLE="amap://styles/ec9aed1c9d38f994098efc3ea00ed4a8"

const FormSelect=(props)=>{
  const [form]=Form.useForm();
  // const onFinish=(values)=>{
  //   console.log('form',values)
  // }
  return (
    <div className="form_select">
      <Form
        layout="inline"
        form={form}
        onFinish={props.onFormSubmit}
      >
        <Form.Item name="convergence">
          <Select placeholder="按变形筛选" style={{width:150}} allowClear >
            <Select.Option value="收敛值小于2cm">收敛值小于2cm</Select.Option>
            <Select.Option value="收敛值在2到4cm">收敛值在2到4cm</Select.Option>
            <Select.Option value="收敛值在4到6cm">收敛值在4到6cm</Select.Option>
            <Select.Option value="收敛值大于6cm">收敛值大于6cm</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="age">
          <Select placeholder="选择年龄范围" style={{width:160}} allowClear >
            <Select.Option value="运营时间小于2年">运营时间小于2年</Select.Option>
            <Select.Option value="运营时间2到5年">运营时间2到5年</Select.Option>
            <Select.Option value="运营时间5到10年">运营时间5到10年</Select.Option>
            <Select.Option value="运营时间大于10年">运营时间大于10年</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="defect">
          <Select placeholder="选择病害类型" style={{width:130}} allowClear>
            <Select.Option value="渗水">渗水</Select.Option>
            <Select.Option value="滴漏">滴漏</Select.Option>
            <Select.Option value="破损">破损</Select.Option>
            <Select.Option value="裂缝">裂缝</Select.Option>
            <Select.Option value="湿迹">湿迹</Select.Option>
            <Select.Option value="渗泥沙">渗泥沙</Select.Option>
            <Select.Option value="积水">积水</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="top_depth">
          <Select placeholder="选择埋深范围" style={{width:180}} allowClear >
            <Select.Option value="顶埋深小于10m">顶埋深小于10m</Select.Option>
            <Select.Option value="顶埋深介于10~20m">顶埋深介于10~20m</Select.Option>
            <Select.Option value="顶埋深介于20~40m">顶埋深介于20~40m</Select.Option>
            <Select.Option value="顶埋大于40m">顶埋大于40m</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="cover">
          <Select placeholder="按覆盖物筛选" style={{width:130}} allowClear >
            <Select.Option value="广场">广场</Select.Option>
            <Select.Option value="停车场">停车场</Select.Option>
            <Select.Option value="堆场">堆场</Select.Option>
            <Select.Option value="人行道">人行道</Select.Option>
            <Select.Option value="公园">公园</Select.Option>
            <Select.Option value="农田">农田</Select.Option>
            <Select.Option value="建筑">建筑</Select.Option>
            <Select.Option value="水">水</Select.Option>
            <Select.Option value="道路">道路</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">查找管片</Button>
        </Form.Item>
      </Form>
    </div>
  )
}

const popupCenter = ({url, title, w, h}) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
  const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

  const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
  const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  console.log('top',top)
  const newWindow = window.open(url, title, 
    `
    scrollbars=yes,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top+60},
    left=${left}
    `
  )

  if (window.focus) newWindow.focus();
}

class App extends Component {
  constructor() {
    super();
    this.amapEvents = {
      created: (mapInstance) => {
        console.log('高德地图 Map 实例创建成功；如果你要亲自对实例进行操作，可以从这里开始。比如：');
        console.log(mapInstance.getZoom());
      }
    };
    this.markerEvents = {
      created: (markerInstance) => {
        console.log('高德地图 Marker 实例创建成功；如果你要亲自对实例进行操作，可以从这里开始。比如：');
        // console.log(markerInstance.getPosition());
      },
      click:(e)=>{
        console.log('你点击了这个图标',e);
        // window.open('http://www.baidu.com','newwindow','left=100,top=200,height=800,width=600,centerscreen,chrome=yes')
        request('interval/'+e.target.w.extData,'get').then(res=>{
          popupCenter({url:'/ring.html?ringid='+e.target.w.extData+'&title='+decodeURI(e.target.w.title),title:'',w:1200,h:600})
          // this.setState({isModalVisible:true,ringId:e.target.w.extData,ringCharts:res.charts})
        })
        
      }
    }
    this.state = { 
      positions:[],
      isModalVisible:false,
      ringId:'',
      ringCharts:[],
      loading:true
    }
  }

  onFormSubmit=(values)=>{
    this.setState({loading:true})
    this.getMarkerList(values);
  }

  componentDidMount(){
    this.getMarkerList({});
  }

  getMarkerList=(search)=>{
     let newSearch={
        convergence:search.convergence?search.convergence:'',
        age:search.age?search.age:'',
        defect:search.defect?search.defect:'',
        top_depth:search.top_depth?search.top_depth:'',
        cover:search.cover?search.cover:'',
     }
     request('intervals?year=2020&title=jjj&convergence='+newSearch.convergence+'&age='+newSearch.age+'&defect='+newSearch.defect+'&top_depth='+newSearch.top_depth+'&cover='+newSearch.cover,'get').then(res=>{
       this.setState({
         positions:res.intervals.map(p=>{
           return {
             ...p,
             loc:{longitude:p.loc.x,latitude:p.loc.y}
           }
         }),
         loading:false
       })
     })
  }

  handleOk=()=>{
    this.setState({isModalVisible:false})
  }

  handleCancel=()=>{
    this.setState({isModalVisible:false})
  }

  render() { 
    const {positions,isModalVisible,ringId,ringCharts,loading}=this.state;
    return ( 
      <div id="container">
        <FormSelect onFormSubmit={this.onFormSubmit}/>
        <Map zooms={[11,18]} center={[121.473658,31.230378]} events={this.amapEvents} amapkey={AMAP_KEY} version={VERSION} mapStyle={MAP_STYLE}>
            {
              loading?
              <div className="spin">
                <Spin tip="数据加载中......"/>
              </div>:
              positions.map((p)=>{
                return <Marker key={p.id} position={p.loc} extData={p.id} title={`[${p.line_name}]${p.interval_name}`} icon={marker} events={this.markerEvents} />
              })
            }            
        </Map>
        <Modal 
          title="" 
          width={1000}
          visible={isModalVisible} 
          onOk={()=>this.handleOk()} 
          onCancel={()=>this.handleCancel()}
          footer={null}
          destroyOnClose={true}
        >
          <Ring id={ringId} ringCharts={ringCharts}/>
        </Modal>
      </div>
     );
  }
}
 
export default App;

